var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "content continuing-education"
  }, [_vm._m(0), _c("section", {
    staticClass: "continuing-education-content"
  }, [_c("ul", {
    staticClass: "article-list"
  }, _vm._l(_vm.article_list, function (item, index) {
    return _c("li", {
      staticClass: "article-li",
      attrs: {
        "data-id": item.id
      }
    }, [_c("router-link", {
      staticClass: "article-a",
      attrs: {
        to: {
          path: "articleDetail",
          query: {
            id: item.id
          }
        }
      }
    }, [_c("div", {
      staticClass: "article-img-box"
    }, [_c("img", {
      staticClass: "article-img",
      attrs: {
        src: _vm.title + item.newsTitlePic,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "article-text"
    }, [_c("p", {
      staticClass: "date-day"
    }, [_vm._v(_vm._s(_vm._f("monthFilter")(item.newsCreateTime)) + "." + _vm._s(_vm._f("dateFilter")(item.newsCreateTime)))]), _c("h3", {
      staticClass: "article-title"
    }, [_vm._v(_vm._s(item.newsTitle))])])])], 1);
  }), 0), _vm.language == 1 ? _c("section", {
    staticClass: "page-box space-around"
  }, [_c("span", [_vm._v("每页 " + _vm._s(_vm.page_size) + " 条")]), _c("span", [_vm._v("共 " + _vm._s(_vm.total_page) + " 页")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(-1);
      }
    }
  }, [_vm._v("上一页")]), _c("span", [_vm._v("第 " + _vm._s(_vm.page_num) + " 页")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(+1);
      }
    }
  }, [_vm._v("下一页")])]) : _vm._e(), _vm.language == 2 ? _c("section", {
    staticClass: "page-box space-around"
  }, [_c("span", [_vm._v(_vm._s(_vm.page_size) + " entries per page")]), _c("span", [_vm._v("total " + _vm._s(_vm.total_page) + " page")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(-1);
      }
    }
  }, [_vm._v("page up")]), _c("span", [_vm._v("current " + _vm._s(_vm.page_num))]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(+1);
      }
    }
  }, [_vm._v("page down")])]) : _vm._e(), _vm.language == 3 ? _c("section", {
    staticClass: "page-box space-around"
  }, [_c("span", [_vm._v("每頁 " + _vm._s(_vm.page_size) + " 條")]), _c("span", [_vm._v("共 " + _vm._s(_vm.total_page) + " 頁")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(-1);
      }
    }
  }, [_vm._v("上一頁")]), _c("span", [_vm._v("第 " + _vm._s(_vm.page_num) + " 頁")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(+1);
      }
    }
  }, [_vm._v("下一頁")])]) : _vm._e()])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/shgy.jpg")
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };